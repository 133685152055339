import Format from 'types/format.type';
import Templates from 'components/data/Templates';
import cloneDeep from 'helpers/cloneDeep';
import CreativeBuilderHelpers from 'components/creative-builder/CreativeBuilderHelpers';
import { TemplateCreativeAsset } from '../types/creative-management.type';
import { getTemplateIdentifierFromCreative } from '.';

const getAvailabeFormatsFromCreative = async (creative: TemplateCreativeAsset): Promise<Format[]> => {
    let availableFormats: Format[] = [];
    const template = await Templates.asyncGetTemplatesItem(creative.subType, getTemplateIdentifierFromCreative(creative) as any);

    if (template?.settings?.formats) {
        const originalFormats = cloneDeep(template.settings.formats);
        if (originalFormats.length !== availableFormats.length) {
            availableFormats = CreativeBuilderHelpers.createAssetFormatsArray(creative.subType, originalFormats);
        }
    }

    return availableFormats;
};

export { getAvailabeFormatsFromCreative };
