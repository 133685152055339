/**
 * When a creative is loaded and possible edited in the CreativeBuilder, the data model is stored in the CreativeEditor store.
 * This CreativeEditor store is removed though when the user previews the creative in the overlay.
 * Otherwise problems arise when switching between latest and released versions.
 * To keep track of changes made in the CreativeBuilder, the uuid is stored in this helper class.
 * The data model can be constructed from the uuid and the EDITOR_BASE_MODEL.
 * So when can reference the right data when saving the creative
 */

class EditCreativeUuidHelpers {
    private static uuid: string | null = null;

    static getCreativeUuid(): string | null {
        return this.uuid;
    }

    static setCreativeUuid(uuid: string): void {
        this.uuid = uuid;
    }

    static clearCreativeUuid(): void {
        this.uuid = null;
    }
}

export { EditCreativeUuidHelpers };
