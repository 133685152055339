import Format from 'types/format.type';
import { unset } from 'lodash';
import { AssetSetup, CreativeAssetToCreate, CreativeDataV1, TemplateCreativeData } from '../types/creative-management.type';
import { isTemplateCreativeType } from './isCreative';

const convertCreativeV1ToV2 = (creativeData: CreativeDataV1): CreativeAssetToCreate => {
    if (isTemplateCreativeType(creativeData.type)) {
        const newSettings = {
            frames: creativeData.frames,
            activeFormats: (creativeData.assetSetup as AssetSetup).settings.formats.map((format: Format) => format.key)
        };

        unset(creativeData, 'assetSetup.settings.formats');
        unset(creativeData, 'assetSetup.availableFormats');

        return {
            data: {
                templateIdentifier: (creativeData.assetSetup as AssetSetup).templateIdentifier,
                templateType: (creativeData.assetSetup as AssetSetup).type,
                templateInput: creativeData.data.main.assetData,
                settings: {
                    ...newSettings,
                    ...(creativeData.assetSetup as AssetSetup).settings
                }
            } as TemplateCreativeData,
            title: creativeData.title,
            type: 'creative',
            subType: creativeData.type
        };
    }

    return {
        data: {
            assetSetup: creativeData.assetSetup,
            channelSetup: creativeData.channelSetup,
            data: creativeData.data,
            frames: creativeData.frames,
            subsets: creativeData.subsets
        },
        title: creativeData.title,
        type: 'creative',
        subType: creativeData.type
    };
};

export { convertCreativeV1ToV2 };
