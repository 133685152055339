import {
    CODED_CREATIVE_TYPES,
    CREATIVE_BUILDER_CREATIVE_TYPES,
    CUSTOM_CREATIVE_TYPES,
    TD_CREATIVE_TYPES,
    TEMPLATE_CREATIVE_TYPES,
    VISUAL_STACK_CREATIVE_TYPES
} from '../constants';
import {
    CreativeAsset,
    CodedCreativeAsset,
    TDCreativeAsset,
    TemplateCreativeAsset,
    CustomCreativeAsset,
    CreativeBuilderCreativeAsset,
    VisualStackCreativeAsset
} from '../types/creative-management.type';
import {
    CodedCreativeSubType,
    CreativeBuilderCreativeSubType,
    CreativeSubType,
    CustomCreativeSubType,
    TDCreativeSubType,
    TemplateCreativeSubType,
    VisualStackCreativeSubType
} from '../types/creative-sub-type.type';

/**
 * @param asset Creative Asset
 * @returns boolean if  the Creative type is a Template Designer type.
 */
export const isTemplateDesignerCreative = (asset: CreativeAsset): asset is TDCreativeAsset => {
    return asset !== null && typeof asset === 'object' && typeof asset.subType === 'string' && isTemplateDesignerCreativeType(asset.subType);
};

/**
 * @param asset Creative Asset
 * @returns boolean if the Creative is creative based on template.
 */
export const isCustomCreative = (asset: CreativeAsset): asset is CustomCreativeAsset => {
    return asset !== null && typeof asset === 'object' && typeof asset.subType === 'string' && isCustomCreativeType(asset.subType);
};

/**
 * @param asset Creative Asset
 * @returns boolean if the creative is based on template and can be edited in CreativeEditor.
 */
export const isTemplateCreative = (asset: CreativeAsset): asset is TemplateCreativeAsset => {
    return asset !== null && typeof asset === 'object' && typeof asset.subType === 'string' && isTemplateCreativeType(asset.subType);
};

/**
 * @param asset Creative Asset
 * @returns boolean if the creative is based on template.
 */
export const isCreativeBuilderCreative = (asset: CreativeAsset): asset is CreativeBuilderCreativeAsset => {
    return asset !== null && typeof asset === 'object' && typeof asset.subType === 'string' && isCreativeBuilderCreativeType(asset.subType);
};

/**
 * @param asset Creative Asset
 * @returns boolean if the creative is a socila channel item or a static asset.
 */
export const isCodedCreative = (asset: CreativeAsset): asset is CodedCreativeAsset => {
    return asset !== null && typeof asset === 'object' && typeof asset.subType === 'string' && isCodedCreativeType(asset.subType);
};

/**
 * @param asset Creative Asset
 * @returns boolean if the creative is an email or a landing page block.
 */
export const isVisualStackCreative = (asset: CreativeAsset): asset is VisualStackCreativeAsset => {
    return asset !== null && typeof asset === 'object' && typeof asset.subType === 'string' && isVisualStackCreativeType(asset.subType);
};

/**
 * @param type Type of creative
 * @returns boolean if the creative is based on a template designer template
 */
export const isTemplateDesignerCreativeType = (type: CreativeSubType): type is TDCreativeSubType => {
    return TD_CREATIVE_TYPES.includes(type as TDCreativeSubType);
};

/**
 * @param type Type of creative
 * @returns boolean if the creative is based on a custom template
 */
export const isCustomCreativeType = (type: CreativeSubType): type is CustomCreativeSubType => {
    return CUSTOM_CREATIVE_TYPES.includes(type as CustomCreativeSubType);
};

/**
 * * @param type Type of creative
 * @returns boolean if the creative is based on a template that can be found in Template Management and can be edited in CreativeEditor
 */
export const isTemplateCreativeType = (type: CreativeSubType): type is TemplateCreativeSubType => {
    return TEMPLATE_CREATIVE_TYPES.includes(type as TemplateCreativeSubType);
};

/**
 * * @param type Type of creative
 * @returns boolean if the creative is based on a template that ca be found in Template Management
 */
export const isCodedCreativeType = (type: CreativeSubType): type is CodedCreativeSubType => {
    return CODED_CREATIVE_TYPES.includes(type as CodedCreativeSubType);
};

/**
 * * @param type Type of creative
 * @returns boolean if the creative is based on a template that ca be found in Template Management
 */
export const isCreativeBuilderCreativeType = (type: CreativeSubType): type is CreativeBuilderCreativeSubType => {
    return CREATIVE_BUILDER_CREATIVE_TYPES.includes(type as CreativeBuilderCreativeSubType);
};

/**
 * * @param type Type of creative
 * @returns boolean if the creative is a email or landingpage block.
 */
export const isVisualStackCreativeType = (type: CreativeSubType): type is VisualStackCreativeSubType => {
    return VISUAL_STACK_CREATIVE_TYPES.includes(type as VisualStackCreativeSubType);
};
