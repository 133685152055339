import CreativeManagementHelpers from 'components/data/CreativeManagementHelpers';
import EditorData from 'components/editor-data/EditorData';
import { CreativeAsset } from '../types/creative-management.type';
import { CreativeBuilderSetupItem } from '../types/creative-builder-item.type';
import { EDITOR_BASE_MODEL } from '../constants';
import { VisualStackBuilderSetupItem } from '../types/visual-stack-builder-item.type';
import { convertCreativeV2ToV1, getTemplateIdentifierFromCreative, isCreativeBuilderCreative, isVisualStackCreative } from '.';

// Setup Redux so the asset can be loaded in the preview, and a non-releaed one in the CreativeEditor.
// Return the dataModel so we can reference it later.
const initCreative = async (
    asset: CreativeAsset,
    released: boolean
): Promise<{ uuid: string; item: CreativeBuilderSetupItem | VisualStackBuilderSetupItem }> => {
    const uuid = (() => {
        const baseUuid = CreativeManagementHelpers.createUuid(asset._id);
        if (released) return `${baseUuid}_released`;
        return baseUuid;
    })();

    const dataModel = `${EDITOR_BASE_MODEL}.data.${uuid}`;
    const setupModel = `${EDITOR_BASE_MODEL}.setup`;

    const editorData = await (async () => {
        if (isCreativeBuilderCreative(asset)) {
            const convertedData = await convertCreativeV2ToV1(asset);
            return convertedData;
        } else if (isVisualStackCreative(asset)) {
            return asset.data.templateInput;
        }
    })();

    const setup = EditorData.getValueFromModel(setupModel) || [];
    const item = {
        uuid,
        referenceId: asset._id,
        identifier: getTemplateIdentifierFromCreative(asset),
        type: asset.subType
    };
    setup.push(item);

    EditorData.setModel(setupModel, setup);
    EditorData.setModel(dataModel, editorData);

    return { uuid, item };
};

export { initCreative };
