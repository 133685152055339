import React, { useEffect, useState } from 'react';
import ComponentStoreHelpers from 'components/data/ComponentStore';
import Loader from 'components/ui-components/Loader';
import AssetManagementHelpers from 'components/asset-management/helpers';
import AssetManagementConfigProvider from 'components/asset-management/context/asset-management-config.context';
import AssetManagementSelector from 'components/asset-management/components/selector';
import { AssetManagementConfigEnriched } from 'components/asset-management/types/asset-management-config.type';
import { CREATIVE_MANAGEMENT_CONFIG, CREATIVE_MANAGEMENT_DEFAULT_SETUP } from 'components/creative-management-v2/constants';
import { CreativeSelectorProps } from 'components/creative-management-v2/types/creative-selector-props.type';
import { CreativeAsset } from 'components/creative-management-v2/types/creative-management.type';
import CreativeManagementSelectorTile from './tile';

const { storeName, type, setupResource } = CREATIVE_MANAGEMENT_CONFIG;

const CreativeManagementSelector: React.FC<CreativeSelectorProps> = ({ dataFilters, subType, collectionQuery, onSelectItem }) => {
    const [enrichedConfig, setEnrichedConfig] = useState<AssetManagementConfigEnriched | null>(null);

    //Initialize the Component Store for Creative Management so we have acces to the filtersetup.
    useEffect(() => {
        const initManagement = async () => {
            const setup = await AssetManagementHelpers.getAssetManagementSetup(setupResource, CREATIVE_MANAGEMENT_DEFAULT_SETUP);
            const enrichedConfig = await AssetManagementHelpers.getEnrichedConfig(CREATIVE_MANAGEMENT_CONFIG, setup);
            if (enrichedConfig) setEnrichedConfig(enrichedConfig);
        };
        if (!ComponentStoreHelpers.get(storeName)) {
            AssetManagementHelpers.init(CREATIVE_MANAGEMENT_CONFIG);
        }
        initManagement();
    }, []);

    return (
        <Loader isLoading={!enrichedConfig}>
            <AssetManagementConfigProvider config={enrichedConfig as AssetManagementConfigEnriched}>
                <AssetManagementSelector
                    onSelect={(item) => onSelectItem(item as CreativeAsset)}
                    dataFilters={dataFilters}
                    preFilters={subType ? { subType } : undefined}
                    collectionQuery={collectionQuery}
                    type={type}
                    assetTile={<CreativeManagementSelectorTile />}
                />
            </AssetManagementConfigProvider>
        </Loader>
    );
};

export default CreativeManagementSelector;
