import React from 'react';
import Illustration from 'components/ui-components-cape/Illustration';
import { useAssetManagementConfigContext } from 'components/asset-management/context/asset-management-config.context';
import { CreativeAsset } from 'components/creative-management-v2/types/creative-management.type';
import { isVisualStackCreative } from 'components/creative-management-v2/utilities';

import '../styles/preview.scss';

interface Props {
    creative: CreativeAsset;
}

const CreativeManagementAssetTilePreview = ({ creative }: Props) => {
    const { assetsPlaceholder } = useAssetManagementConfigContext();
    const thumbnailUrl = (() => {
        if (isVisualStackCreative(creative) && creative.data.image?.length) return creative.data.image;
        return creative.data.thumbnail?.url;
    })();

    if (!thumbnailUrl) return assetsPlaceholder && <Illustration illustration={assetsPlaceholder} />;

    return <img src={thumbnailUrl} className="creative-management-asset-tile-preview" />;
};

export default CreativeManagementAssetTilePreview;
