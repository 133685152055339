import cloneDeep from 'helpers/cloneDeep';
import { AssetSetup, CreativeAsset, CreativeDataV1, CodedCreativeAsset, TemplateCreativeAsset } from '../types/creative-management.type';
import { isTemplateCreative } from './isCreative';
import { getAvailabeFormatsFromCreative } from './getAvailableFormatsFromCreative';
import { getTemplateIdentifierFromCreative } from '.';

const convertCreativeV2ToV1 = async (asset: CreativeAsset): Promise<CreativeDataV1> => {
    if (isTemplateCreative(asset)) {
        const availableFormats = await getAvailabeFormatsFromCreative(asset);

        const cleanedSettings = cloneDeep((asset as TemplateCreativeAsset).data.settings);

        delete cleanedSettings?.activeFormats;
        delete cleanedSettings?.frames;

        const creativeDataV1 = {
            assetSetup: {
                templateIdentifier: getTemplateIdentifierFromCreative(asset),
                type: asset.subType,
                settings: {
                    formats: (asset as TemplateCreativeAsset).data.settings?.activeFormats?.map((formatKey: string) =>
                        availableFormats.find((format) => format.key === formatKey)
                    ),
                    availableFormats,
                    ...cleanedSettings
                }
            } as AssetSetup,
            data: {
                main: {
                    channelData: { type: 'none' },
                    assetData: (asset as TemplateCreativeAsset).data.templateInput
                }
            },
            frames: (asset as TemplateCreativeAsset).data.settings?.frames || 0,
            title: asset.title,
            type: asset.subType,
            subsets: ['main'],
            groupKey: asset.labels?.groupKey?.[0] ?? null
        };

        return creativeDataV1;
    }

    delete asset.data.thumbnail;

    return {
        ...(asset as CodedCreativeAsset).data,
        type: asset.subType,
        title: asset.title,
        groupKey: asset.labels?.groupKey?.[0] ?? null
    };
};

export { convertCreativeV2ToV1 };
