import CreativeManagementHelpers from 'components/data/CreativeManagementHelpers';
import AssetGalleryStore from 'components/assets/AssetGalleryDialog/helpers/asset-gallery-store';
import ComponentStore from 'components/data/ComponentStore';
import { AssetSetup, CreativeAsset } from '../types/creative-management.type';
import { convertCreativeV2ToV1, isCustomCreative } from '.';

const initCreativeEditing = async (asset: CreativeAsset, dataModel: string): Promise<void> => {
    const uuid = CreativeManagementHelpers.createUuid(asset._id);
    const convertedData = await convertCreativeV2ToV1(asset);
    const dynamicFrames = (() => {
        if (convertedData.channelSetup?.settings?.dynamicFrames) {
            return convertedData.channelSetup.settings.dynamicFrames;
        } else if ((convertedData.assetSetup as AssetSetup)?.settings?.dynamicFrames) {
            return (convertedData.assetSetup as AssetSetup).settings.dynamicFrames;
        }
        return false;
    })();

    // Only the latest verion can be edited.
    const creativeEditorData = {
        inCreativeManagement: true,
        viewType: 'edit',
        dataModel,
        uuid,
        referenceId: asset._id,
        multilanguage: true,
        hideFormats: false,
        orderedAssetTemplates: [],
        campaignId: 0,
        subsetActive: isCustomCreative(asset) && convertedData.subsets ? convertedData.subsets[0] : 'main',
        interfaceTabActive: 0,
        dynamicFrames
    };

    ComponentStore.resetData('CreativeEditor', creativeEditorData);

    if (['displayAd', 'displayAdDesigned'].includes(asset.subType)) {
        AssetGalleryStore.fill(convertedData);
    }
};

export { initCreativeEditing };
