import { AssetSetup, CreativeAsset, CodedCreativeData } from '../types/creative-management.type';
import { isTemplateCreative, isVisualStackCreative } from './isCreative';

// Get the template identifier from a creative asset.
const getTemplateIdentifierFromCreative = (creative: CreativeAsset): string => {
    if (isTemplateCreative(creative)) return creative.data.templateIdentifier;
    if (isVisualStackCreative(creative)) return creative.data.templateIdentifier;
    if (creative.subType === 'socialChannelItem') return (creative.data as CodedCreativeData).channelSetup?.templateIdentifier as string;
    return ((creative.data as CodedCreativeData).assetSetup as AssetSetup).templateIdentifier;
};

export { getTemplateIdentifierFromCreative };
