import EditorData from 'components/editor-data/EditorData';
import { EDITOR_BASE_MODEL } from '../constants';

const getCreativeDataModel = (uuid: string): string => {
    return `${EDITOR_BASE_MODEL}.data.${uuid}`;
};

const getCreativeSetupModel = (): string => {
    return `${EDITOR_BASE_MODEL}.setup`;
};

const getCreativeSetupItem = (uuid: string): string => {
    const setupModel = getCreativeSetupModel();
    const setup = EditorData.getValueFromModel(setupModel) || [];
    const item = setup.find((item: any) => item.uuid === uuid);
    return item;
};

export { getCreativeDataModel, getCreativeSetupModel, getCreativeSetupItem };
